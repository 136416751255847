import React from 'react';
import {
  FiPhone,
  FiMail,
  FiSend,
  FiFacebook,
  FiInstagram,
  FiMapPin,
  FiClock,
} from 'react-icons/fi';
import { Icon28LogoVkOutline } from '@vkontakte/icons';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Section from '../components/section';

export default function Contacts({ data }) {
  const pageInfo = {
    id: 'contacts-page',
    url: '/contacts',
    title: 'Контакты',
    description: 'Контакты КМК клиники.',
    breadcrumbs: [
      {
        title: 'Главная',
        link: '/',
      },
      {
        title: 'Контакты',
        link: null,
      },
    ],
  };

  return (
    <Layout pageInfo={pageInfo}>
      <Section>
        <article>
          <h2>
            Наши контакты
          </h2>
          <div className="contacts-container">
            <div className="contact">
              <span className="contact__icon">
                <FiPhone
                  style={{ verticalAlign: 'middle', width: '24px', height: '24px' }}
                  color="#2055a4"
                />
                &nbsp;&nbsp;
              </span>
              <span className="contact__label">Телефон:&nbsp;</span>
              <span className="contact__value">
                <a href={`tel:${data.strapiGeneral.phone}`} target="_blank">{data.strapiGeneral.phone}</a>
              </span>
            </div>
            <div className="contact">
              <span className="contact__icon">
                <FiMail
                  style={{ verticalAlign: 'middle', width: '24px', height: '24px' }}
                  color="#2055a4"
                />
                &nbsp;&nbsp;
              </span>
              <span className="contact__label">Email:&nbsp;</span>
              <span className="contact__value">
                <a href={`mailto:${data.strapiGeneral.email}`} target="_blank">{data.strapiGeneral.email}</a>
              </span>
            </div>
            {
              data.strapiGeneral.vkontakte
                ? (
                  <div className="contact">
                    <span className="contact__icon">
                      <Icon28LogoVkOutline
                        style={{ display: 'inline-block', verticalAlign: 'middle', margin: '-1px' }}
                        color="#2055a4"
                      />
                      &nbsp;&nbsp;
                    </span>
                    <span className="contact__label">VK:&nbsp;</span>
                    <span className="contact__value">
                      <a href={data.strapiGeneral.vkontakte} target="_blank">КМК-клиника</a>
                    </span>
                  </div>
                )
                : null
            }
            {
              data.strapiGeneral.telegram
                ? (
                  <div className="contact">
                    <span className="contact__icon">
                      <FiSend
                        style={{ verticalAlign: 'middle', width: '24px', height: '24px' }}
                        color="#2055a4"
                      />
                      &nbsp;&nbsp;
                    </span>
                    <span className="contact__label">Telegram:&nbsp;</span>
                    <span className="contact__value">
                      <a href={data.strapiGeneral.telegram} target="_blank">{data.strapiGeneral.telegram.split('/').pop()}</a>
                    </span>
                  </div>
                )
                : null
            }
            {/* Meta (Facebook and Instagram) is considered as an extremist organization on territory of the Russian Federation. */}
            {
              data.strapiGeneral.facebook
                ? (
                  <div className="contact">
                    <span className="contact__icon">
                      <FiFacebook
                        style={{ verticalAlign: 'middle', width: '24px', height: '24px' }}
                        color="#2055a4"
                      />
                      &nbsp;&nbsp;
                    </span>
                    <span className="contact__label">Facebook:&nbsp;</span>
                    <span className="contact__value">
                      <a href={data.strapiGeneral.facebook} target="_blank">{data.strapiGeneral.facebook.split('/').pop()}</a>
                    </span>
                  </div>
                )
                : null
            }
            {
              data.strapiGeneral.instagram
                ? (
                  <div className="contact">
                    <span className="contact__icon">
                      <FiInstagram
                        style={{ verticalAlign: 'middle', width: '24px', height: '24px' }}
                        color="#2055a4"
                      />
                      &nbsp;&nbsp;
                    </span>
                    <span className="contact__label">Instagram:&nbsp;</span>
                    <span className="contact__value">
                      <a href={data.strapiGeneral.instagram} target="_blank">{data.strapiGeneral.instagram.split('/').pop()}</a>
                    </span>
                  </div>
                )
                : null
            }
          </div>
          <h2>
            Наш адрес
          </h2>
          <div className="contacts-container">
            <div className="contact">
              <span className="contact__icon">
                <FiMapPin
                  style={{ verticalAlign: 'middle', width: '24px', height: '24px' }}
                  color="#2055a4"
                />
                &nbsp;&nbsp;
              </span>
              <span className="contact__label">Адрес:&nbsp;</span>
              <span className="contact__value">{data.strapiGeneral.address}</span>
            </div>
          </div>
          <p>
            {data.strapiGeneral.howToGet}
          </p>
          <div className="yandex-map">
            <iframe
              src="https://yandex.ru/map-widget/v1/?um=constructor%3Aa9ddf1a0a1689cdc58463f3d8c43b3a6942fe42fc81e736640ab44f297f5cbf6&amp;source=constructor"
              width="100%"
              height="400"
              frameborder="0"
            />
          </div>
          <h2>
            Часы работы
          </h2>
          <div className="contacts-container">
            <div className="contact">
              <span className="contact__icon">
                <FiClock
                  style={{ verticalAlign: 'middle', width: '24px', height: '24px' }}
                  color="#2055a4"
                />
                &nbsp;&nbsp;
              </span>
              <span className="contact__label">Пн - Пт:&nbsp;</span>
              <span className="contact__value">{data.strapiGeneral.weekdaysWorkingHours}</span>
            </div>
            <div className="contact">
              <span className="contact__icon">
                <FiClock
                  style={{ verticalAlign: 'middle', width: '24px', height: '24px' }}
                  color="#fe0b54"
                />
                &nbsp;&nbsp;
              </span>
              <span className="contact__label">Сб - Вс:&nbsp;</span>
              <span className="contact__value">{data.strapiGeneral.weekendsWorkingHours}</span>
            </div>
          </div>
        </article>
      </Section>
    </Layout>
  );
}

export const query = graphql`
  query {
    strapiGeneral {
      name
      phone
      email
      vkontakte
      telegram
      facebook
      instagram
      address
      howToGet
      weekdaysWorkingHours
      weekendsWorkingHours
    }
  }
`;
